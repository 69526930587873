
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import utils from '../../../util';

export default Vue.extend({
  components: {
    linkedType: () => import('../_linkedTypeFB.vue'),
    FacebookLoginNew: () => import('../../linearadmin/components/facebookLoginNew.vue'),
  },
  data: (): {
    googleAuthMessage: string | null;
    googleAuthError: string | null;
    isAgency: boolean;
    agencyConnections: object | null;
    headers: Array<object>;
    bus: Vue;
  } => ({
    googleAuthMessage: null,
    googleAuthError: null,
    isAgency: true,
    agencyConnections: null,
    headers: [
      {
        text: '',
        sortable: false,
      },
      {
        text: 'Facebook Ads',
        sortable: false,
      },
      {
        text: 'Facebook Pages',
        sortable: false,
      },
      {
        text: '',
        sortable: false,
      },
    ],
    bus: new Vue(),
  }),
  created(): void {
    this.loadAgency();
  },
  methods: {
    clearMessages(): void {
      // reset any messages
      this.googleAuthMessage = null;
      this.googleAuthError = null;
    },
    async loadAgency(): Promise<void> {
      try {
        this.clearMessages();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const resp: AxiosResponse<any> = await this.$store.dispatch('agencyAdmin/getFacebookCredentials', {
          id: this.agencyId,
        });
        this.agencyConnections = resp;
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('loadAgency error', exp);
      }
    },
    async remove(facebookUserId): Promise<void> {
      try {
        await this.$store.dispatch('agencyAdmin/removeFacebookCredential', {
          agencyId: this.agencyId,
          userId: facebookUserId,
        });
        this.loadAgency();
        this.bus.$emit('logout', facebookUserId);
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error(exp);
      }
    },
    async forceFacebookSync(facebookUserId): Promise<void> {
      try {
        await this.$store.dispatch('agencyAdmin/syncFacebookCredential', {
          agencyId: this.agencyId,
          userId: facebookUserId,
        });
        this.loadAgency();
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error(exp);
      }
    },
    linkedTypes(types): Array<object> {
      return Array.isArray(types) ? types : [types];
    },
    hasIndividualRight(roles: Array<string>): boolean {
      return utils.hasRight(this.$store.state.customer.user, roles);
    },
    nameCheck(first: string, last: string, connected: boolean): string {
      return connected ? `${first} ${last}` : 'Disconnected User';
    },
  },
  computed: {
    agencyId(): string {
      return this.$route.params.agency;
    },
    filteredConnections(): Array<object> {
      return this.agencyConnections || [];
    },
    connections(): boolean {
      return this.filteredConnections.length > 0;
    },
  },
});
